<template lang="pug">
#about
    textarea.message(placeholder="")
    //- h1 This is an about page!!!
    //- textarea(type="textarea" v-model="message"  @input="inputs" max-length="150")
    //- textarea(type="phone" v-model="phone"  @input="inputphone" max-length="150")
    //- div {{len}}/150
    //- div.active( v-if="active" @click="submit") 提交
    //- div(v-else) 提交
    //- div.animited.fainbootom(v-if="show") 我睡弹框
</template>
<script>

export default {
    data() {
        return {
            message: "",
            phone: "",
            len: 0,
            active: false,
            show: false,
        };
    },
    methods: {
        inputs() {
            this.len = this.message.length;

            this.changeactive();
        },
        inputphone() {
            this.changeactive();
        },
        changeactive() {
            var reg = /^0?1[3|4|5|6|7|8][0-9]\d{8}$/;
            if (this.len > 0 && reg.test(this.phone)) this.active = true;
            else {
                this.active = false;
            }
        },
    },
    submit() {
        this.show = true;
        // 提交 调接口 不用判空
    },
};
</script>
<style lang="stylus" scoped>
.message {
    height: 150px;
    line-height: 25px;
    padding: 5px 10px;
    background: #fff;
    width: 100%;
}
</style>
